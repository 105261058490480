import React from 'react'

function Map() {
  return (
    <>
        <div className="map fix" style={{ background: "#f5f5f5" }}>
            <div className="container-flud">
                <div className="row">
                <div className="col-lg-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.2479185969596!2d2.2382400760023162!3d48.89161219836863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e665269ef3302f%3A0xccc1141b97d47181!2s1%20bis%20Pl.%20de%20la%20D%C3%A9fense%2C%2092400%20Courbevoie!5e0!3m2!1sfr!2sfr!4v1727452903070!5m2!1sfr!2sfr" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" />
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Map