import React from 'react'
import Image from '../Image/Main'

function Touch() {
  return (
    <>
        <section id="services" className="services-area pt-120 pb-90 fix">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h5>
                        <i className="fal fa-graduation-cap" /> Nous contacter
                    </h5>
                    <h2>Nous sommes disponibles pour vous</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <Image name="ImageContactIcon1" />
                    </div>
                    <div className="services-content2">
                        <h5>
                            <a href="tel:+33000000000">+33 0 00 00 00 00</a>
                            <br />
                            <a href="tel:+33000000000">+225 00 00 00 00 00</a>
                        </h5>
                        <p>Téléphone</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center active">
                    <div className="services-icon">
                        <Image name="ImageContactIcon2" />
                    </div>
                    <div className="services-content2">
                        <h5>
                        <a href="mailto:contact@skillsbridge-academy.com">
                            contact@skillsbridge-academy.com
                        </a>
                        </h5>
                        <p>Adresse email</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <Image name="ImageContactIcon3" />
                    </div>
                    <div className="services-content2">
                        <h5>1 bis Place de La Défense, 92400 Courbevoie</h5>
                        <p>Bureau</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Touch