import React from 'react'
import Image from '../Image/Main'

function Form() {
  return (
    <>
        <section id="services" className="services-area pt-120 pb-90 fix" style={{ background: "#e7f0f8" }}>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 order-2">
                    <div className="contact-bg">
                    <div className="section-title center-align text-center mb-50">
                        <h2>Je candidate en ligne</h2>
                    </div>
                    <form action="mail.php" method="post" className="contact-form mt-30 text-left" >
                        <h3>Informations personnelles</h3>
                        <div className="row">
                            <div className="col-lg-2">
                                <label for="gender">Genre</label>
                                <div className="contact-field p-relative mb-30">
                                    <select type="text" id="gender" name="gender">
                                        <option value="">Genre</option>
                                        <option value="m">Homme</option>
                                        <option value="f">Femme</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <label for="firstname">Prénom</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="firstname" name="firstname" placeholder="Prénom" required />
                                    <i className="icon fal fa-user" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="lastname">Nom</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="lastname" name="lastname" placeholder="Nom" required />
                                    <i className="icon fal fa-user" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="birthdate">Date de naissance</label>
                                <div className="contact-field p-relative c-subject mb-30">
                                    <input type="date" id="birthdate" name="birthdate" placeholder="Date de naissance" required />
                                <i className="icon fal fa-calendar" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <label for="department">Département de naissance</label>
                                <div className="contact-field p-relative mb-30">
                                    <input type="text" id="department" name="department" placeholder="" />
                                    <i className="icon fal fa-map-marker" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="birthplace">Ville/Commune de naissance</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="birthplace" name="birthplace" placeholder="Ville de naissance" required />
                                    <i className="icon fal fa-map-marker" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="nationality">Nationalité</label>
                                <div className="contact-field p-relative c-subject mb-30">
                                    <input type="text" id="nationality" name="nationality" placeholder="" required />
                                <i className="icon fal fa-flag" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="country">Pays d'inscription</label>
                                <div className="contact-field p-relative c-subject mb-30">
                                    <input type="text" list="country" placeholder="Pays d'inscription" required />
                                    <i className="icon fal fa-flag" />
                                </div>
                                <datalist id="country">
                                    <option value="CI">Côte d'Ivoire</option>
                                    <option value="FR">France</option>
                                    <option value="SN">Sénégal</option>
                                </datalist>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <label for="address">Adresse</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="address" name="address" placeholder="Adresse" required />
                                    <i className="icon fal fa-map-marker" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="city">Ville</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="city" name="city" placeholder="Ville" required />
                                    <i className="icon fal fa-map-marker" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="email">Adresse email</label>
                                <div className="contact-field p-relative c-subject mb-30">
                                    <input type="email" id="email" name="email" placeholder="Email" required />
                                <i className="icon fal fa-envelope" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="phone">Téléphone</label>
                                <div className="contact-field p-relative c-subject mb-30">
                                    <input type="phone" id="phone" name="phone" placeholder="Téléphone" required />
                                <i className="icon fal fa-phone" />
                                </div>
                            </div>
                        </div>
                        <h3>Scolarité</h3>
                        <div className="row">
                            <div className="col-lg-3">
                                <label for="lastdiploma">Dernier diplôme ou titre préparé</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="lastdiploma" name="lastdiploma" placeholder="Dernier diplôme ou titre préparé" required />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <label for="lastdiplomadetails">Intitulé précis du dernier diplôme où titre préparé</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="lastdiplomadetails" name="lastdiplomadetails" placeholder="Intitulé précis du dernier diplôme où titre préparé" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="birthdate">Dernière classe ou année suivie</label>
                                <div className="contact-field p-relative c-subject mb-30">
                                    <input type="email" id="email" name="email" placeholder="Dernière classe ou année suivie" />
                                </div>
                            </div>
                        </div>
                        <h3>Justificatifs</h3>
                        <div className="row">
                            <div className="col-lg-3">
                                <label for="lastdiploma">Dernier diplôme ou titre préparé</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="lastdiploma" name="lastdiploma" placeholder="Dernier diplôme ou titre préparé" required />
                                </div>
                            </div>
                        </div>
                        <h3>CV & Lettre de motivation</h3>
                        <div className="row">
                            <div className="col-lg-3">
                                <label for="cv">Curriculum Vitae</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="file" id="cv" name="cv" required />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label for="lm">Lettre de motivation</label>
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="file" id="lm" name="lm" required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-field p-relative c-message mb-30">
                                <label for="message">Motivation</label>
                                <textarea name="message" id="message" cols={30} rows={50} placeholder="Quelles sont vos motivations ?" defaultValue={""} />
                                <i className="icon fal fa-edit" />
                            </div>
                            <div className="slider-btn  text-center">
                            <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s" >
                                Valider et payer <i className="fal fa-long-arrow-right" />
                            </button>
                            </div>
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Form