import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'

function BachelorComMarkCo() {
  return (
    <>
        <section className="project-detail">
            <div className="container">
                <div className="lower-content">
                <div className="row">
                    <div className="text-column col-lg-9 col-md-9 col-sm-12">
                    <h2>
                        Bachelor<br />
                        Commerce, Marketing et Communication
                    </h2>
                    <div className="upper-box">
                        <div className="single-item-carousel owl-carousel owl-theme">
                        <figure className="image">
                            <Image name="ImageCourseCommerce" />
                        </figure>
                        </div>
                    </div>
                    <div className="inner-column">
                        <div className="course-meta2 review style2 clearfix mb-30">
                            <ul className="left">
                                <li>
                                    <div className="author">
                                        <div className="thumb">
                                            <Image name="ImageAvatar" />
                                        </div>
                                        <div className="text">
                                        <Link to="/single-courses-2">Prénom NON</Link>
                                        <p>Enseignant principal</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="right">
                                <li className="price">LinkedIn</li>
                            </ul>
                        </div>
                        <h3>Présentation</h3>
                        <p>
                        Ce parcours a pour vocation de former des étudiants capables de concevoir et mettre en œuvre des actions commerciales et de marketing efficace. Ce programme vise à préparer les futurs professionnels à relever les défis du secteur, qu'ils évoluent dans des entreprises de services, des industries ou des structures commerciales. Il permet aux étudiants de développer les compétences nécessaires afin de s’adapter aux dynamiques changeantes du marché et aux évolutions des comportements des consommateurs. L’approche pédagogique favorise l’acquisition à la fois des savoirs théoriques et des compétences pratiques, en mettant l’accent sur la mise en situation réelle et le contact direct avec le monde professionnel.
                        </p>
                        <h4>Compétences visées</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Acquérir les fondamentaux du commerce et des techniques de vente.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Découvrir les outils de marketing digital et de communication.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Apprendre à analyser le comportement des consommateurs.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Développer des campagnes marketing simples et efficaces.
                                </div>
                            </li>
                        </ul>
                        <h4>Spécificités de la formation</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Formation initiale ou alternance.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Cours à distance et en présentiel.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Ateliers pratiques avec des professionnels du secteur.
                                </div>
                            </li>
                        </ul>
                        <h4>Conditions d'Accès</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Accessible après un Bac ou équivalent.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Sélection basée sur dossier et entretien de motivation.
                                </div>
                            </li>
                        </ul>
                        <h4>Débouchés professionnels</h4>
                        <p>
                        Assistant commercial, Chargé.e de communication junior, Assistant marketing, etc.
                        </p>
                    </div>
                    </div>
                    <div className="col-lg-3">
                    <aside className="sidebar-widget info-column">
                        <div className="inner-column3">
                        <h3>Informations</h3>
                        <ul className="project-info clearfix">
                            <li>
                                <div className="priceing">
                                    <strong>
                                        3500€ <br />
                                        <small className="text-secondary text-xs">Bachelor 1</small>
                                    </strong>
                                    <span className="discont">Pré-inscription 100€</span>
                                </div>
                            </li>
                            <li>
                            <span className="icon fal fa-home-lg-alt" />{" "}
                            <strong>Instructor:</strong> <span>Eleanor Fant</span>
                            </li>
                            <li>
                            <span className="icon fal fa-book" />{" "}
                            <strong>Pré-requis:</strong> <span>Bac ou équivalent</span>
                            </li>
                            <li>
                            <span className="icon fal fa-clock" />{" "}
                            <strong>Durée: </strong> <span>9 mois</span>
                            </li>
                            <li className="hide">
                                <span className="icon fal fa-user" />{" "}
                                <strong>Classe: </strong> <span>20 étudiants</span>
                            </li>
                            <li>
                                <span className="icon fal fa-globe" />{" "}
                                <strong>Langue: </strong> <span>Français</span>
                            </li>
                            <li>
                                <span className="icon fal fa-user-graduate" />{" "}
                                <strong>Diplôme: </strong> <span>Titre de niveau 7 (Eu) enregistré au RNCP*</span>
                            </li>
                            <li>
                                <div className="slider-btn">
                                    <Link to="/candidature" className="btn ss-btn smoth-scroll">
                                    S'inscrire <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </aside>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default BachelorComMarkCo