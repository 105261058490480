import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'

function BachelorRH() {
  return (
    <>
        <section className="project-detail">
            <div className="container">
                <div className="lower-content">
                <div className="row">
                    <div className="text-column col-lg-9 col-md-9 col-sm-12">
                    <h2>
                        Bachelor<br />
                        Chargé de RH et de gestion du capital humain
                    </h2>
                    <div className="upper-box">
                        <div className="single-item-carousel owl-carousel owl-theme">
                        <figure className="image">
                            <Image name="ImageCourseRH" />
                        </figure>
                        </div>
                    </div>
                    <div className="inner-column">
                        <div className="course-meta2 review style2 clearfix mb-30">
                        <ul className="left">
                            <li>
                                <div className="author">
                                    <div className="thumb">
                                        <Image name="ImageAvatar" />
                                    </div>
                                    <div className="text">
                                    <Link to="/single-courses-2">Prénom NON</Link>
                                    <p>Enseignant principal</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="right">
                            <li className="price">LinkedIn</li>
                        </ul>
                        </div>
                        <h3>Présentation</h3>
                        <p>
                        Ce parcours forme les étudiants aux fondamentaux de la gestion du capital humain dans les organisations, les préparant à exercer plusieurs postes dans le domaine des ressources humaines. Cette formation offre aux étudiants l’opportunité d’acquérir les compétences essentielles pour contribuer à la gestion des talents, à la dynamique d’équipe et à la performance globale des entreprises. Elle couvre des secteurs divers tels que l’industrie, les services, et le commerce, tout en encourageant l’initiative et l’immersion dans le monde professionnel.
                        </p>
                        <h4>Compétences visées</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Acquérir les fondamentaux du recrutement et de la gestion des compétences.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Découvrir les principaux outils RH pour améliorer la performance des équipes.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Comprendre les enjeux de diversité, d'inclusion, et de bien-être au travail.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Analyser les principaux défis des ressources humaines dans les entreprises modernes.
                                </div>
                            </li>
                        </ul>
                        <h4>Spécificités de la formation</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    En alternance ou en formation initiale.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Cours en ligne et en présentiel pour plus de flexibilité.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Interventions d’experts du secteur.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Études de cas pratiques et mini-projets.
                                </div>
                            </li>
                        </ul>
                        <h4>Conditions d'Accès</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Accessible après un Bac ou équivalent.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Sélection basée sur dossier et entretien de motivation.
                                </div>
                            </li>
                        </ul>
                        <h3>Débouchés professionnels</h3>
                        <p>
                        Chargé.e de RH généraliste, Assistant RH, Chargé.e de recrutement, Assistant RH, etc.
                        </p>
                    </div>
                    </div>
                    <div className="col-lg-3">
                    <aside className="sidebar-widget info-column">
                        <div className="inner-column3">
                        <h3>Informations</h3>
                        <ul className="project-info clearfix">
                            <li>
                                <div className="priceing">
                                    <strong>
                                        3500€ <br />
                                        <small className="text-secondary text-xs">Bachelor 1</small>
                                    </strong>
                                    <span className="discont">Pré-inscription 100€</span>
                                </div>
                            </li>
                            <li>
                            <span className="icon fal fa-home-lg-alt" />{" "}
                            <strong>Instructor:</strong> <span>Eleanor Fant</span>
                            </li>
                            <li>
                            <span className="icon fal fa-book" />{" "}
                            <strong>Pré-requis:</strong> <span>Bac ou équivalent</span>
                            </li>
                            <li>
                            <span className="icon fal fa-clock" />{" "}
                            <strong>Durée: </strong> <span>9 mois</span>
                            </li>
                            <li className="hide">
                                <span className="icon fal fa-user" />{" "}
                                <strong>Classe: </strong> <span>20 étudiants</span>
                            </li>
                            <li>
                                <span className="icon fal fa-globe" />{" "}
                                <strong>Langue: </strong> <span>Français</span>
                            </li>
                            <li>
                                <span className="icon fal fa-user-graduate" />{" "}
                                <strong>Diplôme: </strong> <span>Titre de niveau 7 (Eu) enregistré au RNCP*</span>
                            </li>
                            <li>
                                <div className="slider-btn">
                                    <Link to="/candidature" className="btn ss-btn smoth-scroll">
                                    S'inscrire <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </aside>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default BachelorRH