import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import Form from '../menadmission/Form' 

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        subtitle="Admission"/>
        <Form/>
    </>
  )
}

export default Main