import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'

function MasterComMarkCo() {
  return (
    <>
        <section className="project-detail">
            <div className="container">
                <div className="lower-content">
                <div className="row">
                    <div className="text-column col-lg-9 col-md-9 col-sm-12">
                    <h2>
                        Master 1 & 2 <br />
                        Commerce, Marketing et Communication
                    </h2>
                    <div className="upper-box">
                        <div className="single-item-carousel owl-carousel owl-theme">
                        <figure className="image">
                            <Image name="ImageCourseCommerce" />
                        </figure>
                        </div>
                    </div>
                    <div className="inner-column">
                        <div className="course-meta2 review style2 clearfix mb-30">
                        <ul className="left">
                            <li>
                                <div className="author">
                                    <div className="thumb">
                                        <Image name="ImageAvatar" />
                                    </div>
                                    <div className="text">
                                    <Link to="/single-courses-2">Prénom NON</Link>
                                    <p>Enseignant principal</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="right">
                            <li className="price">6800€</li>
                        </ul>
                        </div>
                        <h3>Présentation</h3>
                        <p>
                        Le Parcours Commerce, Marketing et Communication forme des professionnels aptes à la conception et à l'exécution de stratégies commerciales et marketing percutantes. Dans un environnement commercial en constante évolution, cette formation vous donne les clés pour maximiser l'impact de vos actions marketing et de communication.
                        </p>
                        <h4>Compétences visées</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Concevoir des stratégies de vente performantes et évaluer leur efficacité.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Utiliser les outils digitaux pour optimiser les campagnes de communication.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Analyser les comportements consommateurs et prévoir les tendances du marché.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Savoir analyser les comportements consommateurs et anticiper les tendances du marché.
                                </div>
                            </li>
                        </ul>
                        <h4>Spécificités de la formation</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    En alternance, en formation continue ou initiale.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Formation à distance et en présentiel pour une flexibilité maximale.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Modules interactifs animés par des professionnels expérimentés.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Séminaires de formation en présentiel avec des professionnels du secteur.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Formation axée sur les pratiques digitales innovantes.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Études de cas réels et projets en entreprise pour une mise en pratique immédiate.
                                </div>
                            </li>
                        </ul>
                        <h4>Conditions d'Accès</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Selon le niveau Bac, Bac+2, Licence et Master ou équivalent.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    La sélection des candidatures s’effectue sur dossier et, le cas échéant, sur entretien de motivation, validation du projet professionnel et tests.
                                </div>
                            </li>
                        </ul>
                        <h3>Débouchés professionnels</h3>
                        <p>
                        Commercial.e, Responsable Commercial.e, Responsable marketing, Chargé.e de communication, Chargé.e de gestion commerciale, Responsable d’unité commerciale, Chef de produit, Digital marketer, Growth Hacker, Influenceur Manager, Spécialiste en marketing automation, etc.
                        </p>
                    </div>
                    </div>
                    <div className="col-lg-3">
                    <aside className="sidebar-widget info-column">
                        <div className="inner-column3">
                        <h3>Informations</h3>
                        <ul className="project-info clearfix">
                            <li>
                                <div className="priceing">
                                    <strong>
                                        6800€ <br />
                                        <small className="text-secondary text-xs">Master 1</small>
                                    </strong>
                                    <span className="discont">Pré-inscription 100€</span>
                                </div>
                            </li>
                            <li>
                            <span className="icon fal fa-home-lg-alt" />{" "}
                            <strong>Instructor:</strong> <span>Eleanor Fant</span>
                            </li>
                            <li>
                            <span className="icon fal fa-book" />{" "}
                            <strong>Pré-requis:</strong> <span>Bac +3 minimum</span>
                            </li>
                            <li>
                            <span className="icon fal fa-clock" />{" "}
                            <strong>Durée: </strong> <span>9 mois</span>
                            </li>
                            <li className="hide">
                                <span className="icon fal fa-user" />{" "}
                                <strong>Classe: </strong> <span>20 étudiants</span>
                            </li>
                            <li>
                                <span className="icon fal fa-globe" />{" "}
                                <strong>Langue: </strong> <span>Français</span>
                            </li>
                            <li>
                                <span className="icon fal fa-user-graduate" />{" "}
                                <strong>Diplôme: </strong> <span>Titre de niveau 7 (Eu) enregistré au RNCP*</span>
                            </li>
                            <li>
                                <div className="slider-btn">
                                    <Link to="/candidature" className="btn ss-btn smoth-scroll">
                                    S'inscrire <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </aside>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default MasterComMarkCo