import React from 'react'
import Image04 from '../../assets/img/bg/an-img-04.png'
import Image05 from '../../assets/img/bg/an-img-05.png'

function Frequently() {
  return (
    <>
        <section className="faq-area pt-120 pb-120 p-relative fix">
            <div className="animations-10">
                <img src={Image04} alt="an-img-01" />
            </div>
            <div className="animations-08">
                <img src={Image05} alt="contact-bg-an-01" />
            </div>
            <div className="container">
                <div className="row justify-content-center  align-items-center">
                <div className="col-lg-7">
                    <div
                    className="section-title wow fadeInLeft animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                    >
                    <h2>Notre dispositif pédagogique</h2>
                    <p>
                        A business or organization established to provide a particular
                        service, typically one that involves a organizing transactions.
                    </p>
                    </div>
                    <div
                    className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    >
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                <button
                                    className="faq-btn"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                >
                                    Nos formateurs
                                </button>
                                </h2>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse show"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body">
                                Nos formateurs sont tous des professionnels experts dans leurs domaines d’activités respectifs. Ils cumulent plusieurs dizaines d'années d'expérience. <br />
                                Ils bénéficient tous les ans de formations de formateurs sur les dernières innovations pédagogiques. <br /> Leur intervention est principalement axée sur une pédagogie interactive, combinant ateliers pratiques, acquisition de connaissances, simulations de cas réels, partage d’expérience de bonnes pratiques et réseautage.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                <button
                                    className="faq-btn collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                >
                                    Notre dispositif hybride
                                </button>
                                </h2>
                            </div>
                            <div
                                id="collapseOne"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body">
                                Notre modèle hybride intègre un dispositif innovant proposant des cours en distanciel, en alternance et des sessions en présentiel sous forme de séminaires, et s’articulant autour d’ateliers pratiques et d’examens. <br />
                                Conçus sur la base des référentiels et syllabus de certificateurs français et européens des centres de formation et d’apprentissage, nos cours confèrent les compétences requises par les meilleurs professionnels européens.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                <button
                                    className="faq-btn collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                >
                                    Nos séminaires pratiques
                                </button>
                                </h2>
                            </div>
                            <div
                                id="collapseTwo"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body">
                                Notre format de séminaires implique des ateliers de mise en situation de cas pratique à l’effet de consolider l’apprentissage par une projection en situation réelle. De plus, les étudiants reçoivent des business succès de leurs domaines à travers des conférences thématiques dédiées.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h2 className="mb-0">
                                <button
                                    className="faq-btn collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                >
                                    Nos coaching
                                </button>
                                </h2>
                            </div>
                            <div
                                id="collapseFour"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body">
                                L’accompagnement à la réalisation des mémoires et à la préparation aux examens reste pour nous un élément clé dans la finalisation des parcours de formation. <br />
                                Plus qu’une quête de meilleur résultat, cette activité constitue un vrai levier de consolidation des acquis et une assurance d’une meilleure mise en œuvre des compétences à l’aune des ateliers et séminaires pratiques.
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="contact-bg02" style={{ backgroundColor: "#3d77b7" }}>
                        <div
                            className="section-title wow fadeInDown animated"
                            data-animation="fadeInDown"
                            data-delay=".4s"
                        >
                            <h3 className="text-white">Notre ingénierie pédagogique</h3>
                        </div>
                        <div class="mt-30">
                            <p className="text-white">
                                Notre force réside dans une ingénierie pédagogique innovante, spécialement conçue pour répondre aux défis complexes. S'appuyant sur les dernières avancées en matière de pédagogie et de management, nous formons des compétences adaptées aux réalités professionnelles émergentes. <br /><br />
                                Nos enseignements sont orientés sur la pratique, la pédagogie positive et sur l'apprentissage basé sur la résolution des problèmes (Problem-Based Learning - PBL). Cette approche permet aux apprenants de développer des compétences concrètes et applicables dans leur environnement professionnel.
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Frequently