import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Image from '../Image/Main'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>
      
    );
}



function Courses(props) {
    const { level } = props;
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: <SamplePrevArrow/>,
		nextArrow: <SampleNextArrow/>,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
      };


  return (
    <>
        <section className="courses pt-120 pb-120 p-relative fix">
            <div className="animations-01">
                <Image name="ImageBg03" />
            </div>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 p-relative">
                    <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                        <h5>
                            <i className="fal fa-graduation-cap" /> Parcours de formation
                        </h5>
                        <h2>Nos formations</h2>
                    </div>
                </div>
                </div>
                <Slider className="row class-active" {...settings}>
                    {(!level || level == 'bachelor') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                            <div className="thumb fix">
                                <Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain">
                                    <Image name="ImageCourseRH" />
                                </Link>
                            </div>
                            <div className="courses-content">
                                <div className="cat">
                                    <i className="fal fa-graduation-cap" /> Bachelor 2 & 3
                                </div>
                                <h3>
                                <Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain">Chargé de RH et de gestion du capital humain</Link>
                                </h3>
                                <p>
                                Ce parcours forme les étudiants aux fondamentaux de la gestion du capital humain dans les organisations, les préparant à exercer plusieurs postes dans le domaine des ressources humaines.
                                </p>
                                <Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain" className="readmore">
                                + d'infos <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                            <div className="icon">
                                <Image name="ImageCouIcon" />
                            </div>
                        </div>
                    </div>}
                    {(!level || level == 'master') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                            <div className="thumb fix">
                                <Link to="/courses/master/rh-et-management-du-capital-humain">
                                    <Image name="ImageCourseRH" />
                                </Link>
                            </div>
                            <div className="courses-content">
                                <div className="cat">
                                    <i className="fal fa-graduation-cap" /> Master 1 & 2
                                </div>
                                <h3>
                                <Link to="/courses/master/rh-et-management-du-capital-humain"> RH et Management du capital humain</Link>
                                </h3>
                                <p>
                                Le Parcours RH et Management du Capital Humain est conçu pour former des professionnels capables de transformer le potentiel humain en avantage concurrentiel.
                                </p>
                                <Link to="/courses/master/rh-et-management-du-capital-humain" className="readmore">
                                + d'infos <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                            <div className="icon">
                                <Image name="ImageCouIcon" />
                            </div>
                        </div>
                    </div>}
                    {(!level || level == 'bachelor') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles">
                                <Image name="ImageCourseManagement" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                            <i className="fal fa-graduation-cap" /> Bachelor 2 & 3
                            </div>
                            <h3>
                            <Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles">Chargé de pilotage des unités opérationnelles</Link>
                            </h3>
                            <p>
                            Ce parcours prépare les étudiants à comprendre et acquérir les principaux leviers de gestion d’unités opérationnelles au sein d’une organisation.
                            </p>
                            <Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                    {(!level || level == 'master') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles">
                                <Image name="ImageCourseManagement" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                            <i className="fal fa-graduation-cap" /> Master 1 & 2
                            </div>
                            <h3>
                            <Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles"> Management des Organisation et des unités opérationnelles</Link>
                            </h3>
                            <p>
                            Notre parcours en Management des Organisations et des Unités Opérationnelles est conçu pour les futurs professionnels souhaitant maîtriser les rouages de la gestion d’équipe et des opérations.
                            </p>
                            <Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                    {(!level || level == 'bachelor') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication">
                                <Image name="ImageCourseCommerce" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                            <i className="fal fa-graduation-cap" /> Bachelor 2 & 3
                            </div>
                            <h3>
                            <Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication">Chargé de Commerce, Marketing et Communication</Link>
                            </h3>
                            <p>
                            Ce parcours a pour vocation de former des étudiants capables de concevoir et mettre en œuvre des actions commerciales et de marketing efficace.
                            </p>
                            <Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                    {(!level || level == 'master') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/master/commerce-marketing-et-communication">
                                <Image name="ImageCourseCommerce" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                            <i className="fal fa-graduation-cap" /> Master 1 & 2
                            </div>
                            <h3>
                            <Link to="/courses/master/commerce-marketing-et-communication"> Commerce Marketing et Communication</Link>
                            </h3>
                            <p>
                            Le Parcours Commerce, Marketing et Communication forme des professionnels aptes à la conception et à l'exécution de stratégies commerciales et marketing percutantes.
                            </p>
                            <Link to="/courses/master/commerce-marketing-et-communication" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                    {(!level || level == 'bachelor') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/bachelor/responsable-qualite-et-qhse">
                                <Image name="ImageCourseQhse" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                                <i className="fal fa-graduation-cap" /> Bachelor 2 & 3
                            </div>
                            <h3>
                            <Link to="/courses/bachelor/responsable-qualite-et-qhse">Responsable Qualité et QHSE</Link>
                            </h3>
                            <p>
                            Ce parcours prépare les étudiants à relever les défis liés à la gestion de la qualité, de la sécurité, de la santé et de l’environnement au sein des organisations.
                            </p>
                            <Link to="/courses/bachelor/responsable-qualite-et-qhse" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                    {(!level || level == 'master') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/master/management-de-la-qualite-qhse">
                                <Image name="ImageCourseQhse" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                                <i className="fal fa-graduation-cap" /> Master 1 & 2
                            </div>
                            <h3>
                            <Link to="/courses/master/management-de-la-qualite-qhse"> Management de la qualité QHSE </Link>
                            </h3>
                            <p>
                            Le Parcours Management de la Qualité QHSE est spécialement conçu pour former des experts capables de mettre en place et de gérer les systèmes QHSE et des politiques de durabilité au sein des entreprises.
                            </p>
                            <Link to="/courses/master/management-de-la-qualite-qhse" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                    {(!level || level == 'bachelor') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech">
                                <Image name="ImageCourseBanque" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                            <i className="fal fa-graduation-cap" /> Bachelor 2 & 3
                            </div>
                            <h3>
                            <Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech">Chargé des opérations Banque, Assurance et Fintech</Link>
                            </h3>
                            <p>
                            Ce parcours prépare les étudiants à la compréhension et à l’intégration des évolutions rapides des secteurs financiers et technologiques.
                            </p>
                            <Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                    {(!level || level == 'master') && <div className="col-lg-4 col-md-6 ">
                        <div className="courses-item mb-30 hover-zoomin">
                        <div className="thumb fix">
                            <Link to="/courses/master/banque-assurance-et-fintech">
                                <Image name="ImageCourseBanque" />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="cat">
                            <i className="fal fa-graduation-cap" /> Master 1 & 2
                            </div>
                            <h3>
                            <Link to="/courses/master/banque-assurance-et-fintech"> Banque Assurance et Fintech</Link>
                            </h3>
                            <p>
                            Le Parcours Banque, Assurance et Fintech offre une formation de pointe pour comprendre et intégrer les innovations qui transforment le secteur financier.
                            </p>
                            <Link to="/courses/master/banque-assurance-et-fintech" className="readmore">
                            + d'infos <i className="fal fa-long-arrow-right" />
                            </Link>
                        </div>
                        <div className="icon">
                            <Image name="ImageCouIcon" />
                        </div>
                        </div>
                    </div>}
                </Slider>
            </div>
        </section>
    </>
  )
}

export default Courses